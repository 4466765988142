<template>
  <div>
    <b-card title="">
      <b-row>
        <b-col cols="6">
          <!-- <p>{{ connectedUsers }}</p> -->
          <h3 class="text-primary">Application Details</h3>
        </b-col>
        <b-col cols="6">
          <b-button
            variant="primary"
            class="btn btn-primary float-right"
            @click="back()"
            size="sm"
          >
            Back
          </b-button>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Application Name" label-for="v-first-name">
                <b-form-input
                  id="v-first-name"
                  readonly
                  v-model="applicationName"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Application Link" label-for="v-first-name">
                <b-form-input
                  id="v-first-name"
                  readonly
                  v-model="applicationLink"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col cols="6">
            <b-form-group label="Firewall" label-for="v-first-name"  v-for="nam in firewall" :key="nam">
              <b-form-input
                id="v-first-name"
                readonly
              
               
              >{{nam.name}}</b-form-input>
            </b-form-group>
          </b-col> -->
            <b-col cols="12">
              <b-form-group
                label="Application Details"
                label-for="v-first-name"
              >
                <!-- <label for="textarea-default">Textarea</label> -->
                <b-form-textarea
                  v-model="applicationDetails"
                  id="textarea-default"
                  readonly
                  rows="3"
                />
              </b-form-group>
            </b-col>
            <div class="col-12" v-for="(app, dtail) in firewall" :key="dtail">
              <b-row>
                <b-col cols="6">
                  <b-form-group label="Cloud Profile" label-for="v-first-name">
                    <b-form-input
                      id="v-first-name"
                      readonly
                      v-model="app.cloud_profile_data.name"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Firewalls" label-for="v-first-name">
                    <b-form-input
                      id="v-first-name"
                      readonly
                      v-model="app.name"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-col cols="12">
              <b-form-group>
                <b-form-checkbox
                  disabled
                  id="checkbox-10"
                  name="checkbox-10"
                  :checked="publicApplication"
                  v-model="publicApplication"
                >
                  Public Application
                </b-form-checkbox>
              </b-form-group>
            </b-col>
             <b-col cols="12">
                <b-form-group>
                  <b-form-checkbox
                    disabled
                    id="checkbox-11"
                    name="checkbox-11"
                    :checked="autoEnroll"
                    v-model="autoEnroll"
                  >
                    Automatic Enroll
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            <div class="col-12" v-for="(port, prt) in ports" :key="prt">
              <b-row>
                <b-col cols="6">
                  <b-form-group label="To Port" label-for="v-first-name">
                    <b-form-input
                      id="v-first-name"
                      readonly
                      v-model="port.to_port"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="From Port" label-for="v-first-name">
                    <b-form-input
                      id="v-first-name"
                      readonly
                      v-model="port.from_port"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="$store.state.app.isAdmin" >
      <b-row>
        <b-col cols="12" class="mb-1">
          <h3 class="text-primary">Connected Users</h3>
        </b-col>
        <b-col>
          <b-table
            bordered
            hover
            responsive
            :fields="fields"
            :items="connectedUsers"
          >
            <template #cell(#)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckbox,
  BTable,
} from "bootstrap-vue";
// import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BTable,
  },
  data() {
    return {
      cloudName: "",
      profileType: "",
      profileOptions: [
        { value: "AWS", text: "AWS" },
        { value: "DGO", text: "Digital Ocean" },
        { value: "LightSail", text: "AWS LightSail" },
      ],
      token: "",
      firewalls: "",
      applicationName: "",
      applicationLink: "",
      firewall: [],
      applicationDetails: "",
      appdetails: [],
      appid: "",
      firewallOptions: [],
      firewall_options: [],
      ports: [],
      publicApplication: false,
      autoEnroll: false,
      connectedUsers: "",
      fields: [
        "#",
        {
          key: "username",
          label: "User",
          sortable: true,
        },
        {
          key: "firewall",
          label: "Firewall",
          sortable: true,
        },
        {
          key: "connected_ip",
          label: "IP",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    // this.fetchGroup();
    this.getAppDetails();
    this.token = this.$store.state.app.token;
  },
  created() {
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
    this.appid = this.$route.params.id;
  },
  methods: {
    back() {
      this.$router.push({ name: "home" });
    },
    getAppDetails: function (appid) {
      const options = {
        method: "GET",
        url:
          process.env.VUE_APP_BASEURL +
          `application/applications/${this.appid}/`,
      };
      var self = this;
      this.$http(options)
        .then((res) => {
          console.log(res.data);
          this.appdetails = res.data;
          this.applicationName = res.data.application_name;
          this.applicationLink = res.data.application_link;
          this.applicationDetails = res.data.description;
          this.publicApplication = res.data.is_public_application;
          this.autoEnroll = res.data.is_automatic_enroll;
          this.firewall = res.data.firewalls;
          this.ports = res.data.port;
          this.connectedUsers = res.data.connected_users;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  background-color: #7367f0 !important;
  color: #fff !important;
}
[dir] .table th,
[dir] .table td {
  padding: 0.72rem 2rem;
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
